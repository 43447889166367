<template>
	<div>
		<creatsuccess :msg="showtype" @recovery="recovery">
			<div class="editgiftdetails" v-loading="detailLoading">
				<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px">
					<el-card class="box-card">
						<div slot="header" class="clearfix">
							<span>基本信息</span>
						</div>
						<div class="content">
							<el-form-item label="活动名称：" prop="Name">
								<el-input v-model="ruleForm.Name" style="width: 220px;" placeholder="最多输入20个字" maxlength="20"></el-input>
							</el-form-item>
							<el-form-item label="活动时间：" required>
								<el-form-item prop="StartTime" class="el-form-date-pick">
									<el-date-picker v-model="ruleForm.StartTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss"
									type="datetime" placeholder="开始时间" :picker-options='maxOptions' :disabled="currentEditType==1&&currentEditStatus=='进行中'">
									</el-date-picker>
								</el-form-item>
								<span style="margin:0 10px;">~</span>
								<el-form-item prop="EndTime" class="el-form-date-pick">
									<el-date-picker v-model="ruleForm.EndTime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" type="datetime"
									placeholder="结束时间" :picker-options='minOptions'>
									</el-date-picker>
								</el-form-item>
							</el-form-item>
							<el-form-item label="活动标签：" prop="ActivityTag">
								<el-input v-model="ruleForm.ActivityFlag" :disabled="currentEditStatus==3" placeholder="最多输入10个字" maxlength="10" style="width:350px"></el-input>
								<active-tag></active-tag>
							</el-form-item>
						</div>
					</el-card>
					<el-card class="box-card" style="margin:15px 0 80px">
						<div slot="header" class="clearfix">
							<span>活动信息</span>
						</div>

						<div class="content" style="width:1000px;">
							<el-form-item label="活动门槛：" required>
								<span>购买指定商品参加</span>
								<div>
									<span :class="{'is-error-button':!ruleForm.ProductList.length}" style="color:#409EFF;cursor: pointer;margin-right:10px" @click="handleSelectProDialog">选择商品</span>
									<el-input v-model="productSearchKey" placeholder="搜索已选商品名称、编码" clearable style="width:300px;"
									@keyup.enter.native="handleFilterSelectes">
										<el-button slot="append" icon="el-icon-search" @click='handleFilterSelectes'></el-button>
									</el-input>
								</div>

								<el-table border :data="paginationProduct" style="width:1000px;margin-top:20px" v-loading="productLoading"
								:row-key="productRowKeys">
									<el-table-column :key="5" label="商品" width="300px">
										<template slot-scope="scope">
											<div class="dialog-name-content">
												<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
												<div class="right">
													<div class="name">
														<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.Name}}</pre>
													</div>
													<!-- <br> -->
													<span style='color:#F56C6C;'>{{scope.row.Delstatus?'已删除':(!scope.row.IsOpen?'已下架':(scope.row.Stock==0?'已售罄':''))}}</span>
													<!-- <span>{{scope.row.State}}</span> -->
												</div>
											</div>
										</template>
									</el-table-column>
									<el-table-column :key="6" prop="ProductPrice" label="价格"></el-table-column>
									<el-table-column :key="7" prop="Stock" label="库存"></el-table-column>
									<el-table-column :key="8" label="操作">
										<template slot-scope="scope">
											<el-button style="color:#F56C6C;" @click="deleteSortPro(scope.row,scope.$index)" type="text" size="small">删除</el-button>
											<!-- <span style="color:#C0C4CC;" type="text" size="small">删除</span> -->
										</template>
									</el-table-column>
								</el-table>
								<el-pagination v-if="proPage.total" style="margin-top:20px;float:right;" @size-change="handleSizeChange"
								@current-change="handleCurrentChange" :current-page="proPage.current" :page-sizes="[5,10, 20]" :page-size="proPage.size"
								layout="total, sizes, prev, pager, next, jumper" :total="proPage.total">
								</el-pagination>

							</el-form-item>

							<el-form-item label="活动权益：" required>
								<span>转盘抽奖</span><span style="color:#409EFF;margin-left:15px;cursor: pointer;" :class="{'is-error-button':!GiftdetailData.length}" @click="selectActive">选择抽奖活动</span>
								<div style="color: #999;">支付成功1个订单，赠送1次抽奖机会</div>
								<!-- 抽奖活动表格 -->
								<el-table :data="GiftdetailData" style="width:1000px;margin:15px 0" v-loading="loading" ref="multipleTable"
								:row-key="rowKeys">
									<el-table-column prop="Name" label="活动">
										<template slot-scope="scope">
											<div class="towLines">{{scope.row.Name}}</div>
											<div style="color: red;margin-left: 5px;">{{scope.row.ActivityWheelSurfDelstatus ? '已删除' : scope.row.ActivityWheelSurfState}}</div>
										</template>
									</el-table-column>
									<el-table-column prop="RoomId" label="活动时间">
										<template slot-scope="scope">
											{{scope.row.StartTime}} 至
											{{scope.row.EndTime}}
										</template>
									</el-table-column>

									<el-table-column prop="RoomId" label="">
										<template slot-scope="scope">
											<span style="color:red;cursor: pointer;" @click="delectData(scope.row,scope.$index)">删除</span>
										</template>
									</el-table-column>
								</el-table>
							</el-form-item>

							<el-form-item label="活动入口图片配置:" label-width="200px">
								<div class="filter-container">
									<div class="filter-item">
										<img v-if="ruleForm.ActivityEntranceImgUrl" :src="imgUrl+ruleForm.ActivityEntranceImgUrl" alt="" style="width:300px;height:72px;margin-right:10px;">
										<el-upload style="width:300px;height:72px" :action="imgApi" :on-success="handleAvatarSuccessfu" :file-list="fileList"
										multiple accept="image/png,image/jpeg,image/jpg,image/gif" :before-upload="beforeAvatarUpload1" :on-remove="handleRemovefu"
										:show-file-list="false">
											<el-button type="text" size="small" style="margin-top:5px;font-size: 14px;" v-if="ruleForm.ActivityEntranceImgUrl">更改图片</el-button>
											<el-button type="text" size="small" style="margin-top:5px;font-size: 14px;" v-if="!ruleForm.ActivityEntranceImgUrl">上传图片</el-button>
										</el-upload>
									</div>

									<div class="filter-item" style="color:#999">
										<div>图片请上传：</div>
										<div>1.建议尺寸长750px;宽100px</div>
										<div>2.大小请控制在1M以内</div>
										<div>3.图片在支付成功页展示，<span style="color:#409EFF;cursor: pointer;" @click="lookBig">点击查看示例图</span></div>
									</div>
								</div>
							</el-form-item>
						</div>

					</el-card>

				</el-form>

				<el-dialog title="选择商品" :visible.sync="selectProDialog" width="1300px" class="dialog">
					<giftdataProduct api="activityPayGiftProductList" :couponData="couponData" :selectedData="ruleForm.ProductList"
					@getSelectList="getSelectList" :visible.sync="selectProDialog" v-if="selectProDialog"></giftdataProduct>
				</el-dialog>

				<!--查看示例图  -->
				<el-dialog title="查看示例图" :visible.sync="lookBigimg" width="500px">
					<img :src="lookImgs" alt="" class="lookImgsWidth">
				</el-dialog>

				<el-dialog title="选择活动" :visible.sync="selectactiveDialog" width="1100px" class="dialog">
					<turntableActivities :visible.sync="selectactiveDialog" :checkShowId="checkShowId" :selectActivedata="GiftdetailData"
					@getActivetList="getActivetList" v-if="selectactiveDialog"></turntableActivities>
				</el-dialog>

				<!-- 保存商品冲突 -->
				<el-dialog title="以下商品与其他活动冲突，请重新设置" :visible.sync="conflictGoodsShow" width="1000px" class="dialog data-dialog">
					<div>
						<el-table border :data="conflictGoodsList" style="max-height:450px;overflow: auto;">
							<el-table-column :key="20" label="商品" width="300px">
								<template slot-scope="scope">
									<div class="dialog-name-content">
										<img :src="imgUrl+scope.row.ImgUrl+'@!cut64'" />
										<div class="right">
											<div class="name">
												{{scope.row.Name}}
											</div>
										</div>
									</div>
								</template>
							</el-table-column>
							<el-table-column :key="21" prop="Remark" label="冲突信息"></el-table-column>
						</el-table>
					</div>
					<div style="text-align:center;margin-top:20px;">
						<el-button class="button" style="width:200px" @click="conflictGoodsShow=false" type="primary">确定</el-button>
					</div>
				</el-dialog>

				<div class="bottom-save-btn">
					<el-button style="width:240px" @click="canselEdit">取消</el-button>
					<el-button type="primary" style="width:240px" @click="submitForm('ruleForm')" :loading="saveLoading">保存</el-button>
				</div>

			</div>
		</creatsuccess>
	</div>
</template>


<script>
	import {
		activityPayGiftSave,
		activityPayGiftInfo
	} from '@/api/goods'

	import config from '@/config/index'
	import giftdataProduct from './giftdataProduct'
	import turntableActivities from './turntableActivities'
	import activeTag from '../components/activeTag'
	import creatsuccess from "@/components/creatsuccess/creatsuccess.vue"
	export default {
		components: {
			giftdataProduct,
			turntableActivities,
			activeTag,
			creatsuccess
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			return {
				currentEditStatus:'',
				loading: false,
				productFilter: '',
				lookBigimg: false,
				imgUrl: config.IMG_BASE,
				imgApi: config.UPLOAD_IMG,
				fileList: [],
				lookImgs: 'https://cdn.dkycn.cn/melyshop/image/20200521-180e75bb-6809-4318-ae19-d17be1c05cd5.png',
				productSearchKey: '',
				productLoading: false,
				selectProDialog: false,
				selectactiveDialog: false,
				saveLoading: false,
				couponData: {},
				detailLoading: false,
				conflictGoodsShow: false,
				GiftdetailData: [],
				paginationProduct: [],
				conflictGoodsList: [],
				selectedFilterList: [],
				ruleForm: {
					Name: '',
					StartTime: '',
					EndTime: '',
					ActivityFlag: '',
					ActivityEntranceImgUrl: '/image/20200519-957e7b38-d3dd-4d69-a0aa-480d353f1317.png',
					ProductList: []
				},
				rules: {
					Name: [{
						required: true,
						// message: '请输入活动名称',
						trigger: 'blur',
						validator: checkName
					}],
					StartTime: [{
						required: true,
						message: '请选择开始日期',
						trigger: 'blur'
					}],
					EndTime: [{
						required: true,
						message: '请选择结束日期',
						trigger: 'blur'
					}],
				},
				proPage: {
					current: 1,
					total: 0,
					size: 5
				},
				checkShowId: 0,

				showtype:{
					issuccess:false,
					id:0,
					type:0,
					suctype:5,
				}

			}
		},
		computed: {
			minOptions: function() {
				let limitTime = this.ruleForm.StartTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						} else {
							return time < new Date(currentDate)
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.ruleForm.EndTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString()) ||
								time < new Date(currentDate)
							)
						} else {
							return time < new Date(currentDate)
						}
					}
				}
			}
		},
		beforeMount() {
			this.currentEditStatus = this.$route.query.status
			this.currentEditType = this.$route.query.type
			this.getInformation()
		},
		methods: {
			recovery(){
				this.$router.push({path:this.$route.path,query:{
					type:2,
					id:0,
					status:''
				}})
				this.showtype.issuccess = false
				this.ruleForm = {
					Name: '',
					StartTime: '',
					EndTime: '',
					ActivityFlag: '',
					ActivityEntranceImgUrl: '/image/20200519-957e7b38-d3dd-4d69-a0aa-480d353f1317.png',
					ProductList: []
				}
				this.GiftdetailData = []
				this.paginationProduct = []
			},
			// 初始化数据
			async getInformation() {
				try {
					this.detailLoading = true;
					if (this.$route.query.Id > 0) {
						let data = {
							Id: this.$route.query.Id
						}
						let result = await activityPayGiftInfo(data)
						this.ruleForm = result.Result
						this.ruleForm.ProductList = this.selectedFilterList = result.Result.ProductList
						this.GiftdetailData = []
						let obj = {
							Id: result.Result.ActivityWheelSurfId,
							Name: result.Result.ActivityWheelSurfName,
							StartTime: result.Result.ActivityWheelSurfStartTime,
							EndTime: result.Result.ActivityWheelSurfEndTime,
							ActivityWheelSurfDelstatus: result.Result.ActivityWheelSurfDelstatus,
							ActivityWheelSurfState: result.Result.ActivityWheelSurfState
						}
						if (this.$route.query.type == 2) {
							this.ruleForm.StartTime = ''
							this.ruleForm.EndTime = ''
						}
						this.ruleForm.ActivityEntranceImgUrl = this.ruleForm.ActivityEntranceImgUrl ? this.ruleForm.ActivityEntranceImgUrl :
							'/image/20200519-957e7b38-d3dd-4d69-a0aa-480d353f1317.png'
						this.GiftdetailData.push(obj)
						this.checkShowId = result.Result.ActivityWheelSurfId
						this.paginationPro()
					}

				} catch (e) {
					
				} finally {
					this.detailLoading = false
				}

			},
			rowKeys(row) {
				return row.Id
			},
			productRowKeys(row) {
				return row.Id
			},

			handleSizeChange(val) {
				this.proPage.size = val;
				this.paginationPro()
			},
			handleCurrentChange(val) {
				this.proPage.current = val;
				this.paginationPro()
			},
			// 取消
			canselEdit() {
				this.$router.push({
					path: '/market/payGifts'
				});
			},
			// 保存
			submitForm(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.conflictGoodsList = []
						try {
							this.saveLoading = true
							if (!this.ruleForm.ProductList.length) {
								// this.$message({
								// 	showClose: true,
								// 	type: 'error',
								// 	message: '商品至少选择一件'
								// })
								
								this.$common.completeFormInformation('商品至少选择一件','is-error-button')
								return
							}
							if (!this.GiftdetailData.length) {
								// this.$message({
								// 	showClose: true,
								// 	type: 'error',
								// 	message: '请选择抽奖活动'
								// })
								
								this.$common.completeFormInformation('请选择抽奖活动','is-error-button')
								return
							}

							let ProductIdList = []
							this.ruleForm.ProductList.map(item => {
								ProductIdList.push(item.Id)
							})
				
							let data = {
								Name: this.ruleForm.Name,
								StartTime: this.ruleForm.StartTime,
								EndTime: this.ruleForm.EndTime,
								ProductIdList: ProductIdList,
								ActivityFlag: this.ruleForm.ActivityFlag,
								ActivityWheelSurfId: this.GiftdetailData[0].Id,
								ActivityEntranceImgUrl: this.ruleForm.ActivityEntranceImgUrl
							}
							if (this.$route.query.type == 1 && this.$route.query.Id > 0) {
								data.Id = this.$route.query.Id
							} else {
								data.Id = 0
							}

							let result = await activityPayGiftSave(data)
							if (result.Result && result.Result.length) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '操作失败'
								});
								this.conflictGoodsList = result.Result
								this.conflictGoodsShow = true
							} else {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								if(this.$route.query.Id==0||this.$route.query.type==2){
									this.showtype = {
										issuccess:true,
										id:result.Result.Id,
										// type:0,
										suctype:5,
									}
								}else{
									this.$router.push({
										path: '/market/payGifts'
									});
								}
							}


						} catch (e) {
							//TODO handle the exception
				
						} finally {

							this.saveLoading = false
						}


					} else {
						this.$common.completeFormInformation('请完善活动信息','is-error')
						console.log('error submit!!');
						return false;
					}
				});

			},

			// 选择抽奖活动
			selectActive() {
				this.selectactiveDialog = true
			},
			//获取选择的商品的列表
			getSelectList(data) {
				this.productSearchKey = ''
				this.ruleForm.ProductList = this.selectedFilterList = data
	
				this.selectProDialog = false
				this.handleFilterSelectes()
			},
			getActivetList(data) {
				this.GiftdetailData = []
				this.GiftdetailData.push(data)
				this.checkShowId = data.Id
				this.selectactiveDialog = false
			},

			//选择商品
			handleSelectProDialog() {
				if ((this.ruleForm.StartTime == '' || this.ruleForm.StartTime == null) || (this.ruleForm.EndTime == '' || this.ruleForm
						.EndTime == null)) {
					this.$message({
						showClose: true,
						type: 'error',
						message: '请先完善活动时间'
					})
				} else {
					this.couponData.id = this.$route.query.type == 2 ? 0 : this.ruleForm.Id
					this.couponData.StartTime = this.ruleForm.StartTime
					this.couponData.EndTime = this.ruleForm.EndTime
					this.selectProDialog = true
				}
			},
			handleFilterSelectes() {
				this.proPage.current = 1
				this.selectedFilterList = []
				this.ruleForm.ProductList.filter(t => {
					if ((t.Name.indexOf(this.productSearchKey) != -1) || (t.ProductNo && (t.ProductNo.indexOf(this.productSearchKey) !=
							-1))) {
						this.selectedFilterList.push(t)
					}
				})
				this.paginationPro()
			},
			// 删除指定商品
			deleteSortPro(val, index) {
				this.ruleForm.ProductList = this.ruleForm.ProductList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				this.selectedFilterList = this.selectedFilterList.filter(t => {
					if (t.Id != val.Id) {
						return t
					}
				})
				if ((this.selectedFilterList.length) % this.proPage.size == 0 && this.proPage.current > 1) {
					this.proPage.current = this.proPage.current - 1;
				}
				this.paginationPro()

			},
			//商品分页
			paginationPro() {
				this.proPage.total = this.selectedFilterList.length;
				let start = (this.proPage.current - 1) * this.proPage.size
				let end = start + this.proPage.size
				let pagination = this.selectedFilterList.slice(start, end);
				this.paginationProduct = pagination
				
		
				this.$forceUpdate()
			},
			// 删除数据
			delectData(row, index) {
				this.checkShowId = 0
				this.GiftdetailData.splice(index, 1)
			},
			// 查看示例图
			lookBig() {
				this.lookBigimg = true
			},
			beforeAvatarUpload1(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif'
				const isLt1M = file.size / 1024 / 1024 < 1;
				if (!isJPG) {
					this.$message.error('上传头像图片必须是 JPG 或者PNG格式!');
				}
				if (!isLt1M) {
					this.$message.error('图片大小请控制在1M以内!');
				}
				return isJPG && isLt1M;
			},
			//上传图片
			handleAvatarSuccessfu(res, file) {
				this.ruleForm.ActivityEntranceImgUrl = file.response[0]

			},
			handleRemovefu(file, fileList) {

			},

		}
	}
</script>

<style lang="less" scoped>
	.editgiftdetails {
		padding: 10px;
		background: #fff;

		.el-form-date-pick {
			display: inline-block;
		}

		.towLines {
			word-break: break-all;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}

		.lookImgsWidth {
			width: 460px;
		}

		.dialog-name-content {
			display: flex;
			flex-direction: row;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			span {
				color: #E51C23;
				font-size: 12px;
			}
		}

		.bottom-save-btn {
			position: fixed;
			width: 100%;
			background: #fff;
			bottom: 0;
			text-align: center;
			padding: 20px 0;
			z-index: 100;
			box-shadow: 13px 1px 6px #999;
		}

	}
</style>
